import React from 'react';
import { DatePicker as AntDatePicker } from 'antd';
import './DatePicker.css';

export default DatePicker;

export type DatePickerProps = {};

function DatePicker({ ...rest }: DatePickerProps) {
    return <AntDatePicker className="DatePicker" {...rest} />;
}
