import React, { useContext, useEffect, useState } from 'react';
import { Spin } from 'antd';
import GwService from '../services/GwService';
import { ClientParamsGwConfig, DocumentCaptureProvider, FaceCaptureProvider } from 'gw-api/types';
import locales from '../data/locales.json';

interface AuthContextInterface {
    transactionId?: string;
    clientId?: number;
    flowId?: number;
    config?: ClientParamsGwConfig;
    defaultDocumentCaptureProvider: DocumentCaptureProvider;
    defaultFaceCaptureProvider: FaceCaptureProvider;
    frontendBaseUrl: string;
    apiBaseUrl: string;
    locale: any;
}
export const ConfigContext = React.createContext<AuthContextInterface>(
    {} as AuthContextInterface
);

ConfigContext.displayName = 'Auth';

export type ConfigProviderProps = {
    children: React.ReactNode;
    defaultTransactionId?: string;
    defaultClientId?: number;
    defaultFlowId?: number;
    onLoad?: () => void;
    config?: any;
};
export const ConfigProvider = ({
    defaultTransactionId,
    defaultClientId,
    defaultFlowId,
    config,
    children,
    onLoad,
}: ConfigProviderProps) => {
    const [transactionId, setTransactionId] = useState<string | undefined>(
        defaultTransactionId
    );
    const [clientId, setClientId] = useState<number | undefined>(
        defaultClientId
    );
    const [flowId, setFlowId] = useState<number | undefined>(defaultFlowId);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        transactionId && GwService.setTransactionId(transactionId);
    }, [transactionId]);

    useEffect(() => {
        clientId && GwService.setClientId(clientId);
    }, [clientId]);

    useEffect(() => {
        // flowId && GwService.setFlowId(transactionId);
    }, [flowId]);

    useEffect(() => {
        if (transactionId && clientId) {
            (async () => {
                await onLoad?.();
            })();
        }
    }, [transactionId, clientId]);

    const defaultDocumentCaptureProvider =
        (process.env
          .REACT_APP_SMARTFACE_DOCUMENT_CAPTURE_DEFAULT_PROVIDER && !Number.isNaN(
            process.env.REACT_APP_SMARTFACE_DOCUMENT_CAPTURE_DEFAULT_PROVIDER
        ) &&
            Number.parseInt(
                process.env
                    .REACT_APP_SMARTFACE_DOCUMENT_CAPTURE_DEFAULT_PROVIDER
            )) ||
        DocumentCaptureProvider.MICROBLINK;
    const defaultFaceCaptureProvider =
        (process.env.REACT_APP_SMARTFACE_FACE_CAPTURE_DEFAULT_PROVIDER && !Number.isNaN(
            process.env.REACT_APP_SMARTFACE_FACE_CAPTURE_DEFAULT_PROVIDER
        ) &&
            Number.parseInt(
                process.env.REACT_APP_SMARTFACE_FACE_CAPTURE_DEFAULT_PROVIDER
            )) ||
        FaceCaptureProvider.IDRND;

    const authContext = {
        config,
        transactionId,
        clientId,
        flowId,
        defaultDocumentCaptureProvider,
        defaultFaceCaptureProvider,
        frontendBaseUrl: process.env.REACT_APP_SMARTFACE_FRONTEND_URL,
        apiBaseUrl: process.env.REACT_APP_SMARTFACE_BASE_API,
        locale: locales[config?.clientDataConfig?.locale] || locales['es-UY'],
    };
    return (
        <ConfigContext.Provider value={authContext}>
            <Spin spinning={loading}>{children}</Spin>
        </ConfigContext.Provider>
    );
};

export function useConfigContext() {
    return useContext(ConfigContext);
}
