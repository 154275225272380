import React, { useState } from 'react';
import './LoginPage.css';
import { Alert, Form, Input, Typography } from 'antd';
import Button from '../common/Button';
import ContentBox from '../common/ContentBox';
import GwService from '../services/GwService';
import { parse as parseQueryString } from 'query-string';
import { ClientParams } from '../App';

export default LoginPage;

export type LoginPageProps = {
    clientConfiguration?: any;
    shouldLogin?: boolean;
    clientId?: number;
    onSuccess?: (result: { clientParams: ClientParams }) => void;
};

function LoginPage({
    clientConfiguration,
    shouldLogin,
    clientId,
    onSuccess,
}: LoginPageProps) {
    const queryObject = parseQueryString(window.location.search);
    const debug = queryObject.debug === '1';
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);

    const handleFinish = async (values: any) => {
        try {
            setLoading(true);
            const response = await GwService.login({
                clientId: values.clientId,
                pass: values.password,
            });
            window.location.assign(response.redirectTo);
        } catch (err) {
            setLoading(false);
            setError(err);
        }
    };

    const handleFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    return (
        <ContentBox className="LoginPage">
            <ContentBox.Header className="LoginPage_header">
                {clientConfiguration?.uiConfig ? (
                    <img
                        src={clientConfiguration.uiConfig.logoUrl}
                        alt="logo"
                        style={{ width: '200px' }}
                    />
                ) : process.env.REACT_APP_SMARTFACE_DEFAULT_LOGO ? (
                    <img
                        src={process.env.REACT_APP_SMARTFACE_DEFAULT_LOGO}
                        alt="logo"
                        style={{ width: '200px' }}
                    />
                ) : null}
            </ContentBox.Header>
            <ContentBox.Body className="LoginPage_body">
                <Form
                    className="LoginPage_form"
                    name="basic"
                    layout="vertical"
                    onFinish={handleFinish}
                    onFinishFailed={handleFinishFailed}
                    initialValues={{
                        clientId: clientId,
                    }}
                >
                    <Form.Item
                        label="ID de cliente"
                        name="clientId"
                        rules={[
                            {
                                required: true,
                                message: 'El ID de cliente es requerido.',
                            },
                        ]}
                    >
                        <Input autoComplete="username" />
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'El password es requerido.',
                            },
                        ]}
                    >
                        <Input.Password autoComplete="current-password" />
                    </Form.Item>

                    {error ? (
                        <>
                            <Alert
                                showIcon
                                message={error.message}
                                type="error"
                            />
                            <br />
                        </>
                    ) : null}

                    <Form.Item style={{ marginTop: '10px' }}>
                        <Button
                            loading={loading}
                            type="primary"
                            htmlType="submit"
                            style={{ width: '100%' }}
                        >
                            Validar
                        </Button>
                    </Form.Item>
                </Form>
            </ContentBox.Body>

            <ContentBox.Footer className="LoginPage_footer">
                <Typography.Text type="secondary">
                    <small>
                        All rights reserved. Legal info | Privacy policy |
                        Compliance
                    </small>
                </Typography.Text>
            </ContentBox.Footer>
        </ContentBox>
    );
}
