import React, { useState } from 'react';
import { GwFlowStepProps, GwFlowStepResult } from '../GwFlow';
import TitleText from '../../common/TitleText';
import Button from '../../common/Button';
import StepContent from '../../common/StepContent';
import { useTranslation } from 'react-i18next';
import CountrySelect, { CountryInfo } from './CountrySelect';

import countries from '../../data/countries.json';
import documents from '../../data/documents.json';
import DocumentTypeSelect from './DocumentTypeSelect';
import {
    DocumentCode,
    GwFlowDocumentTypeOptions,
    DocumentInfo,
    DocumentMap,
    GwFlowDocumentTypeSelectSelectorType,
} from 'gw-api/types';
import { Form } from 'antd';
import DocumentTypeBoxSelect from './DocumentTypeBoxSelect';

const documentMap = documents as unknown as DocumentMap;
const countryMap: { [key: string]: CountryInfo } = countries;

export default GwFlowDocumentTypeSelect;

export type GwFlowDocumentTypeSelectResult = GwFlowStepResult & {
    country?: CountryInfo;
    documentType?: DocumentInfo;
};

export interface GwFlowDocumentTypeSelectProps
    extends Omit<GwFlowStepProps, 'options'> {
    options?: GwFlowDocumentTypeOptions;
}

function GwFlowDocumentTypeSelect({
    onResult,
    onCancel,
    options,
}: Partial<GwFlowDocumentTypeSelectProps>) {
    const { t } = useTranslation();
    const [tmpResult, setTmpResult] = useState<GwFlowDocumentTypeSelectResult>(
        () => ({
            country: options?.defaultCountryCode
                ? countryMap[options?.defaultCountryCode]
                : undefined,
            documentType: options?.defaultDocumentCode
                ? documentMap[options?.defaultDocumentCode]
                : undefined,
        })
    );

    const { availableDocuments, selectorType } = options || {};

    const handleContinueClick = () => {
        onResult?.(tmpResult);
    };
    const handleBackClick = () => {
        onCancel?.();
    };
    const handleCountryChange = (alpha3: string) => {
        setTmpResult({ ...tmpResult, country: countryMap[alpha3] });
    };
    const handleDocumentTypeChange = (code: DocumentCode) => {
        setTmpResult({ ...tmpResult, documentType: documentMap[code] });
    };

    return (
        <StepContent
            header={
                <TitleText size="large">
                    {t('Indica país y tipo de tu documento de identidad')}
                </TitleText>
            }
            body={
                <Form>
                    <Form.Item>
                        <CountrySelect
                            value={tmpResult.country?.alpha3}
                            onChange={handleCountryChange}
                        />
                    </Form.Item>

                    <Form.Item>
                        {selectorType ===
                        GwFlowDocumentTypeSelectSelectorType.RADIO ? (
                            <DocumentTypeBoxSelect
                                availableDocuments={availableDocuments}
                                disabled={!tmpResult.country}
                                value={tmpResult.documentType?.code}
                                onChange={handleDocumentTypeChange}
                            />
                        ) : (
                            <DocumentTypeSelect
                                disabled={!tmpResult.country}
                                availableDocuments={availableDocuments}
                                value={tmpResult.documentType?.code}
                                onChange={handleDocumentTypeChange}
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        <div>
                            {t('Esto nos ayudará a poder validad tu identidad')}
                            .
                        </div>
                    </Form.Item>
                    <Button
                        disabled={
                            !(tmpResult.country && tmpResult.documentType)
                        }
                        type="primary"
                        onClick={handleContinueClick}
                    >
                        {t('Continuar')}
                    </Button>
                </Form>
            }
        />
    );
}
