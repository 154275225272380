import React, { useEffect, useState } from 'react';
import './App.css';
import i18n from './i18n';
import localeEs from 'antd/es/date-picker/locale/es_ES';
import localeEn from 'antd/es/date-picker/locale/en_US';
import localePt from 'antd/es/date-picker/locale/pt_BR';
import { Layout, Result, ConfigProvider as AntConfigProvider } from 'antd';
import { deviceDetect } from 'react-device-detect';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { createStylesheet, getClientParams, updateFavicon } from './helpers';
import GwService from './services/GwService';
import { StyleThemeProvider } from './common/StyleContext';
import DebugComponentsPage from './debug/DebugComponentsPage';
import { ConfigProvider } from './components/ConfigContext';
import GwFlowApp from './GwFlowApp';
import { ClientParamsGwConfig, PostableParams } from "gw-api/types";

// @ts-ignore
import { ApiError } from './services/error';
import LoginPage from './components/LoginPage';

export interface ClientParams extends PostableParams {
    error?: ApiError | null;
    shouldLogin?: boolean;
    transactionId?: string;
    sessionTransfered?: boolean;
    sessionTransferedBack?: boolean;
    stage?: string;
    gwConfig?: ClientParamsGwConfig;
}

const clientParams = getClientParams();

GwService.setAddr(`${process.env.REACT_APP_SMARTFACE_BASE_API}`);
GwService.setStage(
    `${clientParams.stage || process.env.REACT_APP_SMARTFACE_BASE_API_STAGE}`
);
if (clientParams.clientId) {
    GwService.setClientId(clientParams.clientId);
} else {
    console.log('clientId not present in POST params');
}
if (clientParams.transactionId) {
    GwService.setTransactionId(clientParams.transactionId);
} else {
    console.error('transactionId not present');
}

if (!clientParams.redirectUrl) {
    console.log('redirectUrl not present in POST params');
}

// @ts-ignore
const clientConfig = window.SORTVARS.config;

// debug vars form clientDataConfig
// window.SORTVARS.config?.clientDataConfig &&
//     (window.SORTVARS.config.clientDataConfig.mobileOnly = true);

const locales: {[key: string]: any} = {
    ['EN']: localeEn,
    ['ES']: localeEs,
    ['PT']: localePt,
};

const locale = locales[i18n.language.toLocaleUpperCase()]
function App() {
    const [deviceInfo] = useState(() => deviceDetect());
    const [error] = useState(clientParams.error);
    const [clientConfiguration] = useState<ClientParamsGwConfig>(
        clientConfig as ClientParamsGwConfig
    );

    useEffect(() => {
        if (clientConfiguration) {
            const defaultUiConfig = {
                backgroundImage: 'rectangle-10.svg',
                logoUrl: 'logo_FacePhi.svg',
                primaryColor: '#00A5E9',
                // linkColor: '#2757a3',
                // successColor: '#52c41a',
                // warningColor: '#faad14',
                // errorColor: '#f5222d',
                // fontSizeBase: '14px',
                // headingColor: '#000000',
                // textColor: '#000000',
                // textColorSecondary: '#000000',
                borderRadiusBase: '8px',
                // borderColorBase: '#d9d9d9',
                // boxShadowBase:
                //     '0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
            };
            createStylesheet({
                ...defaultUiConfig,
                ...(clientConfiguration.uiConfig
                    ? clientConfiguration.uiConfig
                    : {}),
            });
            let faviconUrl = 'favicon.ico'; //default
            if (clientConfiguration.uiConfig?.favicon) {
                faviconUrl = clientConfiguration.uiConfig.favicon;
            }
            updateFavicon(faviconUrl);
        }
    }, [clientConfiguration]);

    if (error) {
        return <Result status={'error'} title={error.message} />;
    }

    return (
        <Layout className="App">
            <StyleThemeProvider initialStyleTheme={clientConfiguration}>
                <AntConfigProvider locale={locale}>
                    <Layout.Content className="App-content">
                        <div className="site-layout-content">
                            <Router>
                                <Switch>
                                    <Route
                                        exact
                                        path="/debugComponents/:componentName?"
                                        component={DebugComponentsPage}
                                    />
                                    <Route
                                        exact
                                        path="/login"
                                        component={LoginPage}
                                    />
                                    <Route
                                        path="/"
                                        exact={true}
                                        render={() => (
                                            <ConfigProvider
                                                defaultTransactionId={
                                                    window.SORTVARS
                                                        ?.transactionId
                                                }
                                                defaultClientId={
                                                    window.SORTVARS?.clientId
                                                }
                                                config={window.SORTVARS?.config}
                                            >
                                                <GwFlowApp
                                                    defaultValues={
                                                        window.SORTVARS
                                                            ?.defaultValues
                                                    }
                                                    activeStepIndex={
                                                        window.SORTVARS
                                                            ?.nextStepIndex || 0
                                                    }
                                                    flowInOtherDevice={
                                                        !!window.SORTVARS
                                                            ?.flowInOtherDevice
                                                    }
                                                    resultAlreadySent={
                                                        !!window.SORTVARS
                                                            ?.resultSent
                                                    }
                                                    defaultSessionExpired={
                                                        !!window.SORTVARS
                                                            ?.sessionExpired
                                                    }
                                                    initialResult={
                                                        window.SORTVARS?.result
                                                    }
                                                />
                                            </ConfigProvider>
                                        )}
                                    />
                                </Switch>
                            </Router>
                        </div>
                    </Layout.Content>
                </AntConfigProvider>
            </StyleThemeProvider>
        </Layout>
    );
}

export default App;
