import React from 'react';
import { Collapse, Divider } from 'antd';
import { GwConfigStepType } from 'gw-api/types';
import OtpInput from '../common/OtpInput';
import PhoneNumberInput from '../common/PhoneNumberInput';
import Summary from '../components/GwFlowValidate/Summary';
import currentResultForValidate from '../mock/currentResultForValidate';

export default DebugCommon;
export type DebugCommonProps = {};

function DebugCommon({ ...rest }: DebugCommonProps) {
    return (
        <Collapse destroyInactivePanel={true} defaultActiveKey={'Summary'}>
            <Collapse.Panel header="Summary" key="Summary">
                <div style={{ width: '400px' }}>
                    <Summary
                        result={{
                            ...currentResultForValidate,
                            // [GwConfigStepType.DOCUMENT_CAPTURE]: {},
                            // [GwConfigStepType.SELFIE_CAPTURE]: {},
                            // [GwConfigStepType.PERSONAL_INFO]: {},
                            [GwConfigStepType.OTP_SMS]: {
                                phone: '+1234567890',
                            },
                        }}
                    />
                </div>
            </Collapse.Panel>
            <Collapse.Panel header="OtpInput" key="OtpInput">
                <OtpInput />
            </Collapse.Panel>
            <Collapse.Panel header="PhoneNumberInput" key="PhoneNumberInput">
                <div>
                    <Divider>Horizontal</Divider>
                    <PhoneNumberInput showFullCountryName={true} />
                    <Divider>Vertical</Divider>
                    <PhoneNumberInput
                        showFullCountryName={true}
                        direction="vertical"
                    />
                </div>
            </Collapse.Panel>
        </Collapse>
    );
}
