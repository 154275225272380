import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detectBrowserLanguage from 'detect-browser-language';
import es from './lang/es.json';
import en from './lang/en.json';
import pt from './lang/pt.json';
// the translations
const resources = {
    es,
    en,
    pt,
};

let [locale] = detectBrowserLanguage().split('-');
//default to 'es'
locale = ['en', 'es', 'pt'].includes(locale) ? locale : 'es';
const Bod = {
    transactionId: '3267451a-ed94-44e0-8adb-...',
    transaction: {
        validOtp: true,
        gwConfig: {
            clientId: '25',
            flowId: '4',
            forceBackendConfig: true,
            clientDataConfig: {
                authenticateTime: 2,
                civilCheck: false,
                countryCode: 'URY',
                cropFactor: 0.6,
                disableOcr: false,
                documentCaptureRetries: 1,
                documentValidation: false,
                enabledConfirmSelfie: false,
                facialThreshold: 85,
                interactible: false,
                maxRetries: 2,
                mobileOnly: true,
                padValidation: false,
                qrDeviceTransfer: true,
                saasMode: 1,
                selfieCaptureRetries: 1,
                showSummary: false,
                stage: 'dev4',
                wizardMode: 'short',
                videoMode: '',
                checkBlackList: true,
                checkDataValidEmail: true,
                checkDataValidPhone: true,
                checkDataValidIP: true,
            },
            resultConfig: {
                arnSnsNotification: 'arn:aws:sns:us-east-1:77...',
                redirectMethod: 'GET',
                redirectUrl: 'https://intelli-market.v...',
                returnPostData: true,
                returnMethod: 'POST',
                resultUrl: 'https://xiyz-9l7q-eoe5.n...',
                resultHeaders: '',
                resultContentType: 'application/json',
            },
            responseTypeConfig: {
                imageFront: true,
                imageBack: true,
                imageUser: true,
                video: false,
                templateRaw: true,
            },
            uiConfig: {
                logoUrl: 'https://gw-client-logos....',
                primaryColor: '#27699F',
                favicon: 'https://gw-client-logos....',
            },
            formConfig: {
                availableDocuments: { 0: 'ID_CARD', 1: 'PASSPORT' },
                steps: {
                    0: { 0: 'WELCOME', 1: { logoSrc: 'path' } },
                    1: {
                        0: 'OTP_EMAIL',
                        1: { resendTimeout: 9000, type: 'email', retries: 2 },
                    },
                    2: {
                        0: 'PERSONAL_INFO',
                        1: {
                            validations: { 0: { minAge: 18 } },
                            enableEdit: true,
                            fullFields: false,
                            defaultDocumentCountry: 'URY',
                            defaultDocumentType: 'ID_CARD',
                        },
                    },
                    3: {
                        0: 'OTP_SMS',
                        1: { resendTimeout: 9000, type: 'sms', retries: 3 },
                    },
                },
            },
        },
        log: {
            0: {
                timestamp: 1702642527,
                level: 'INFO',
                message: 'Sending results',
                fullMessage: '[2023-12-15T12:15:27.027...',
            },
        },
        deviceInfo: {
            os: 'OS X',
            source: 'Mozilla/5.0 (iPhone; CPU...',
            isMobile: true,
            platform: 'iPhone',
        },
        geoLocation: { message: "'127.0.0.1' is a bogon (..." },
        initialRequestIp: '127.0.0.1',
    },
};
// hardcoding for now the language of the app
locale = 'es';

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: locale,

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
