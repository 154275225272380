import React, { useContext, useMemo, useState } from 'react';
import { GwFlowStepProps } from '../GwFlow';
import { Modal, Tabs } from 'antd';
import GwFlowFaceCaptureTips from './GwFlowFaceCaptureTips';
import { useTranslation } from 'react-i18next';
import GwFlowFaceCaptureConfirm from './GwFlowFaceCaptureConfirm';
import GwFlowFaceCaptureIdrnd, {
    IdrndExtractionResult,
} from './GwFlowFaceCaptureIdrnd';
import GwService, {
    GwLivenessResonseDataResult,
} from '../../services/GwService';
import LoadingWrapper from '../../common/LoadingWrapper';
import BackButtonWrapper from '../../common/BackButtonWrapper';
import TitleText from '../../common/TitleText';
import StepContent from '../../common/StepContent';
import useRetries from '../../common/useRetries';
import { DebugContext } from '../../common/Debug';
import {
    FaceCaptureProvider,
    GwFlowFaceCaptureOptions,
    DataUrl,
} from 'gw-api/types';
import './GwFlowFaceCapture.css';

export default GwFlowFaceCapture;
export interface GwFlowFaceCaptureProviderResult {
    bestImageCropped: DataUrl | null;
    bestImage: DataUrl | null;
    rawFaceCapture?: IdrndExtractionResult;
    livenessResult?: GwLivenessResonseDataResult;
}

export interface GwFlowFaceCaptureProviderError {}

export interface GwFlowFaceCaptureProvider {
    onlyFront?: boolean;
    locale?: string;
    onError?: (error: GwFlowFaceCaptureProviderError) => void;
    onSuccess?: (result: GwFlowFaceCaptureProviderResult) => void;
    onCancel?: () => void;
}

export interface GwFlowFaceCaptureProps
    extends Omit<GwFlowStepProps, 'options'> {
    options?: GwFlowFaceCaptureOptions;
}

function GwFlowFaceCapture({
    options = {},
    onResult,
    onCancel,
    onError,
}: Partial<GwFlowFaceCaptureProps>) {
    const { t, i18n } = useTranslation();
    const { log } = useContext(DebugContext);

    const [loading, setLoading] = useState<boolean>(false);
    const {
        provider = 0,
        showTips,
        showPreview,
        checkLiveness,
        cropFactor,
        retries,
    } = options;
    const { consumeRetry, getRetryError } = useRetries(retries);

    const [tmpResult, setTmpResult] =
        useState<GwFlowFaceCaptureProviderResult>();
    const handleTipClick = () => {
        setActiveKey('scan');
    };

    const validateExtraction = (extractionResult: any) => {
        // validateDocumentType(document!, extractionResult, t);
        // validateCountry(country!, extractionResult, t);
        // validateFaceImage(extractionResult, t);
        // if (!isPassport) {
        //     validateMatchFrontBack(extractionResult, t);
        // }
    };
    const handleCaptureSuccess = async (
        result: GwFlowFaceCaptureProviderResult
    ) => {
        try {
            setLoading(true);
            let livenessResult;
            if (checkLiveness) {
                setActiveKey('liveness');
                setLoading(true);
                const livenessResponse = await GwService.liveness({
                    imageBuffer: result.bestImage as string,
                });
                livenessResult = livenessResponse.result;
            }
            await validateExtraction(result.rawFaceCapture);
            const resultToSend = { ...result, livenessResult };

            setLoading(false);
            if (showPreview) {
                setTmpResult(resultToSend);
                setActiveKey('preview');
            } else {
                onResult?.(resultToSend);
            }
        } catch (err) {
            Modal.confirm({
                title: err.message,
                content: <span>{t('Volver a intentar')}</span>,
                onCancel: () => {
                    onError?.(err);
                },
                onOk: () => {
                    setLoading(false);
                    setActiveKey('scan');
                },
                cancelText: t('Cancelar'),
                okText: t('Reintentar'),
            });
        }
    };
    const handleCaptureError = (error: any) => {
        onError?.(error);
    };
    const handleCaptureCancel = () => {
        if (showPreview) {
            setActiveKey('tips');
        } else {
            onCancel?.();
        }
    };

    const handleCaptureConfirm = () => {
        onResult?.(tmpResult!);
    };

    const handleBackClick = () => {
        const currentIndex = items.findIndex((item) => item.key === activeKey);
        if (currentIndex === 0) {
            onCancel?.();
        } else {
            const newIndex = currentIndex - 1;
            setActiveKey(items[newIndex].key);
        }
    };
    const handleCaptureRetake = () => {
        if (consumeRetry()) {
            setActiveKey('scan');
            log(`Retrying face scan`);
        } else {
            onResult?.({
                status: 'error',
                errorMessage: getRetryError()!.message,
            });
        }
    };
    const items = useMemo(
        () => [
            ...(showTips
                ? [
                      {
                          label: '',
                          key: 'tips',
                          children: (
                              <StepContent
                                  body={
                                      <GwFlowFaceCaptureTips
                                          onClick={handleTipClick}
                                      />
                                  }
                              ></StepContent>
                          ),
                      },
                  ]
                : []),
            {
                label: '',
                key: 'scan',
                children: (
                    <div className={'GwFlowFaceCapture__scan'}>
                        <BackButtonWrapper
                            loading={false}
                            onBackClick={handleBackClick}
                        >
                            {provider === FaceCaptureProvider.IDRND ? (
                                <GwFlowFaceCaptureIdrnd
                                    locale={i18n.language}
                                    onSuccess={handleCaptureSuccess}
                                    onError={handleCaptureError}
                                    onCancel={handleCaptureCancel}
                                />
                            ) : (
                                'Unknown provider'
                            )}
                        </BackButtonWrapper>
                    </div>
                ),
            },
            ...(checkLiveness
                ? [
                      {
                          label: '',
                          key: 'liveness',
                          children: (
                              <StepContent
                                  body={<LoadingWrapper loading={loading} />}
                              ></StepContent>
                          ),
                      },
                  ]
                : []),
            ...(showPreview
                ? [
                      {
                          label: '',
                          key: 'preview',
                          children: (
                              <StepContent
                                  body={
                                      <GwFlowFaceCaptureConfirm
                                          result={tmpResult!}
                                          onConfirm={handleCaptureConfirm}
                                          onCancel={handleCaptureRetake}
                                          confirmText={t('Aceptar')}
                                          cancelText={t('Tomar de nuevo')}
                                      />
                                  }
                              ></StepContent>
                          ),
                      },
                  ]
                : []),
        ],
        [
            options,
            handleTipClick,
            handleCaptureSuccess,
            handleCaptureError,
            handleCaptureCancel,
        ]
    );

    const [activeKey, setActiveKey] = useState<string>(items[0].key);

    return (
        <div className="GwFlowFaceCapture">
            <Tabs
                items={items}
                renderTabBar={() => <span></span>}
                activeKey={activeKey}
                destroyInactiveTabPane={true}
            />
        </div>
    );
}
