import React from 'react';
import { Space } from 'antd';
import Image from '../../common/Image';
import { Trans } from 'react-i18next';
import Confirm, { ConfirmProps } from '../../common/Confirm';
import { GwFlowDocumentCaptureProviderResult } from 'gw-api/types';

export default GwFlowDocumentCaptureConfirm;

export type GwFlowDocumentCaptureConfirmProps = ConfirmProps & {
    result: GwFlowDocumentCaptureProviderResult;
};

function GwFlowDocumentCaptureConfirm({
    result,
    ...rest
}: GwFlowDocumentCaptureConfirmProps) {
    return (
        <Confirm
            content={[
                <Space key="1">
                    <Image
                        style={{ width: '100%', maxWidth: '200px' }}
                        src={result.images.front}
                        className=""
                        alt=""
                    />
                    {result.images.back ? (
                        <Image
                            style={{ width: '100%', maxWidth: '200px' }}
                            src={result.images.back}
                            className=""
                            alt=""
                        />
                    ) : null}
                </Space>,
                <Trans
                    key="3"
                    i18nKey="Asegúrate que <1>la iluminación de las fotografías sea buena</1> y que las <1>letras sean claras</1> antes de continuar"
                >
                    Asegúrate que
                    <strong>
                        la iluminación de las fotografías sea buena
                    </strong>{' '}
                    y que las <strong>letras sean claras</strong> antes de
                    continuar
                </Trans>,
            ]}
            {...rest}
        />
    );
}
