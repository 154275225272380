import React, { useEffect, useRef, useState } from 'react';
import './GwFlowDocumentCaptureMicroblink.css';
import { GwFlowDocumentCaptureProvider } from './GwFlowDocumentCapture';
import StepContent from '../../common/StepContent';
import { isMobile } from 'react-device-detect';
import Icon, { CheckOutlined, LoadingOutlined } from '@ant-design/icons';
import * as BlinkIDSDK from '@microblink/blinkid-in-browser-sdk';
import {
    BlinkIdMultiSideRecognizer,
    Displayable,
    Recognizer,
    RecognizerRunner,
    VideoRecognizer,
    WasmSDK,
    WasmType,
} from '@microblink/blinkid-in-browser-sdk';
import {
    getCompresedImageElement,
    imageDataToDataUrl,
} from '../../common/helpers';
import CardFlip from '../../common/CardFlip';
import BackButtonWrapper from '../../common/BackButtonWrapper';
import { useConfigContext } from '../ConfigContext';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import {
    DocumentCode,
    GwFlowDocumentCaptureProviderResult,
    MicroblinkExtractionResult,
} from 'gw-api/types';
import { RecognizerSettings } from '@microblink/blinkid-in-browser-sdk/src/MicroblinkSDK/DataStructures';
import Space from '../../common/Space';

let wasmSDK: WasmSDK;
let blinkIdRecognizer: Recognizer;
let recognizerRunner: RecognizerRunner;
// (async () => {
//     if (!BlinkIDSDK.isBrowserSupported()) {
//         console.error('This browser is not supported by the SDK!');
//     }
//     const loadSettings = new BlinkIDSDK.WasmSDKLoadSettings(
//         process.env.REACT_APP_SMARTFACE_BLINKID_LICENSE as string
//     );
//     // loadSettings.engineLocation = `${window.location.origin}/blinkIDResources`;
//     loadSettings.engineLocation = `${window.location.origin}/resources`;
//
//     wasmSDK = await BlinkIDSDK.loadWasmModule(loadSettings);
//     blinkIdRecognizer = await BlinkIDSDK.createBlinkIdMultiSideRecognizer(
//         wasmSDK
//     );
//
//     // Update recognizer settings before scan action
//     // https://github.com/BlinkID/blinkid-in-browser/blob/9d07a2b38f2bee3ef5ab30414b1f26545ff4bdc6/src/Recognizers/BlinkID/Generic/BlinkIdRecognizer.ts#L91-L206
//
//     const settings = await blinkIdRecognizer.currentSettings();
//     settings['returnFaceImage'] = true;
//     settings['returnFullDocumentImage'] = true;
//     //@ts-ignore
//     await blinkIdRecognizer.updateSettings(settings);
// })();

export default GwFlowDocumentCaptureMicroblink;

export interface GwFlowDocumentCaptureMicroblinkProps
    extends GwFlowDocumentCaptureProvider {}

function GwFlowDocumentCaptureMicroblink({
    onCancel,
    onSuccess,
    onError,
}: Partial<GwFlowDocumentCaptureMicroblinkProps>) {
    const { t } = useTranslation();
    const [loaded, setLoaded] = useState(false);
    const { locale } = useConfigContext();

    const videoRef = useRef<HTMLVideoElement | null>(null);
    const [scanFeedbackMessage, setScanFeedbackMessage] = useState<
        string | null
    >(null);
    const [scanFeedbackStatus, setScanFeedbackStatus] = useState<string | null>(
        null
    );
    const [scanFeedbackLock, setScanFeedbackLock] = useState<boolean | null>(
        null
    );
    const [switchToBack, setSwitchToBack] = useState<boolean>(false);
    const [isBack, setIsBack] = useState<boolean>(false);

    const handleExtractionFinished = async (
        extractionResult: MicroblinkExtractionResult
    ) => {
        console.log('microblink', extractionResult);

        const extraction: GwFlowDocumentCaptureProviderResult = {
            images: {
                front: (
                    await getCompresedImageElement(
                        extractionResult.fullDocumentFrontImage.rawImage
                    )
                ).src,
                back: extractionResult.fullDocumentBackImage.rawImage
                    ? (
                          await getCompresedImageElement(
                              extractionResult.fullDocumentBackImage.rawImage
                          )
                      ).src
                    : undefined,
                face: imageDataToDataUrl(extractionResult.faceImage.rawImage),
            },
            // @TODO: deprecate userInfo field
            userInfo: normalizeUserInfo(extractionResult, locale.date_format),
            documentInfo: normalizeDocumentInfo(
                extractionResult,
                locale.date_format
            ),
            rawScanResult: extractionResult,
        };
        onSuccess?.(extraction);
    };

    useEffect(() => {
        if (switchToBack) {
            setTimeout(() => {
                setSwitchToBack(false);
            }, 3000);
        }
    }, [switchToBack]);

    useEffect(() => {
        if (!loaded) {
            (async () => {
                if (!wasmSDK) {
                    await loadBlinkIdModule();
                }
                /// ocr
                let videoRecognizer: VideoRecognizer | null = null;
                try {
                    if (!recognizerRunner) {
                        recognizerRunner =
                            await BlinkIDSDK.createRecognizerRunner(
                                wasmSDK,
                                [blinkIdRecognizer],
                                true,
                                {
                                    onQuadDetection: (quad: any) =>
                                        updateScanningFeedback(quad),
                                    onFirstSideResult: () => {
                                        setSwitchToBack(true);
                                        setIsBack(true);
                                    },
                                    // onDetectionFailed: () =>
                                    // updateScanFeedback('Detection failed', true),
                                }
                            );
                    }

                    const cameraFeed: HTMLVideoElement =
                        window.document.getElementById(
                            'myCameraVideoElement'
                        ) as HTMLVideoElement;

                    // const cameraFeed: HTMLVideoElement = await new Promise(
                    //     (resolve) => {
                    //         function getElement() {
                    //             const elm = window.document.getElementById(
                    //                 'myCameraVideoElement'
                    //             ) as HTMLVideoElement;
                    //             if (elm) {
                    //                 resolve(elm);
                    //             } else {
                    //                 setTimeout(getElement, 500);
                    //             }

                    //         }
                    //         setTimeout(getElement);
                    //     }
                    // );
                    // if (!cameraFeed) {
                    //     return;
                    // }
                    videoRecognizer =
                        await BlinkIDSDK.VideoRecognizer.createVideoRecognizerFromCameraStream(
                            cameraFeed,
                            recognizerRunner as RecognizerRunner
                        );

                    if (isMobile) {
                        // frontal camera by default so no need to scaleX(-1)
                        cameraFeed.style.transform = 'none';
                    }
                    setLoaded(true);
                    const processResult = await videoRecognizer.recognize();

                    if (
                        processResult !== BlinkIDSDK.RecognizerResultState.Empty
                    ) {
                        setScanFeedbackStatus('success');
                        const recognitionResult: MicroblinkExtractionResult =
                            (await (
                                blinkIdRecognizer as BlinkIdMultiSideRecognizer
                            ).getResult()) as MicroblinkExtractionResult;

                        videoRecognizer?.releaseVideoFeed();
                        recognizerRunner?.delete();
                        recognizerRunner = null;
                        // blinkIdRecognizer.delete();
                        handleExtractionFinished(recognitionResult);
                        setScanFeedbackStatus(null);
                        setScanFeedbackMessage(null);
                    } else {
                        throw new Error(
                            `${t(
                                'La captura no fue exitosa, resultado'
                            )} ${processResult}`
                        );
                    }
                } catch (error: any) {
                    console.error(error);
                    if (error.name === 'VideoRecognizerError') {
                        // Reason is of type BlinkIDSDK.NotSupportedReason and contains information why video
                        // recognizer could not be used. Usually this happens when user didn't grant access to a
                        // camera or when a hardware or OS error occurs.
                    }
                    onError?.(error);

                    // @ts-ignore
                    if (videoRecognizer) {
                        await videoRecognizer.releaseVideoFeed();
                    }

                    if (recognizerRunner) {
                        await recognizerRunner.delete();
                    }
                    // if (recognizer) {
                    //     await recognizer.delete();
                    // }
                    setScanFeedbackStatus(null);
                    setScanFeedbackMessage(null);
                }
            })();
        }
        return () => {
            // console.log('out');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const updateScanFeedback = (message: string | null, force?: boolean) => {
        if (scanFeedbackLock && !force) {
            return;
        }
        setScanFeedbackLock(true);
        setScanFeedbackMessage(message);
        window.setTimeout(() => setScanFeedbackLock(false), 1000);
    };

    const updateScanningFeedback = (displayable: Displayable) => {
        // console.log(displayable);
        switch (displayable.detectionStatus) {
            case BlinkIDSDK.DetectionStatus.Failed:
                updateScanFeedback(null);
                setScanFeedbackStatus(null);
                break;
            case BlinkIDSDK.DetectionStatus.Success:
            case BlinkIDSDK.DetectionStatus.FallbackSuccess:
                // console.log(displayable);
                updateScanFeedback(null);
                setScanFeedbackStatus('scanning');
                break;
            case BlinkIDSDK.DetectionStatus.CameraAngleTooSteep:
                updateScanFeedback(t('Ajustar el angulo'));
                setScanFeedbackStatus('error');
                break;
            case BlinkIDSDK.DetectionStatus.CameraTooFar:
                updateScanFeedback(t('Acercar el documento'));
                setScanFeedbackStatus('error');
                break;
            case BlinkIDSDK.DetectionStatus.CameraTooClose:
            case BlinkIDSDK.DetectionStatus.DocumentTooCloseToCameraEdge:
            case BlinkIDSDK.DetectionStatus.DocumentPartiallyVisible:
                updateScanFeedback(t('Alejar el documento'));
                setScanFeedbackStatus('error');
                break;
            default:
                console.warn(
                    'Unhandled detection status!',
                    displayable.detectionStatus
                );
        }
    };

    return (
        <StepContent
            bodyProps={{
                style: isMobile ? { padding: 0 } : { maxWidth: '1000px' },
            }}
            body={
                <div>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            position: 'relative',
                            opacity: 1,
                            ...(isMobile
                                ? {
                                      height: '100%',
                                  }
                                : {
                                      height: '80%',
                                  }),
                        }}
                    >
                        <BackButtonWrapper
                            onBackClick={() => onCancel?.()}
                            loading={!loaded}
                            headerExtra={
                                scanFeedbackStatus !== 'success' ? (
                                    <Space className="DocumentScanner_indicator">
                                        <span>
                                            {isBack ? t('Dorso') : t('Frente')}
                                        </span>
                                        <CardFlip
                                            dontFlip={true}
                                            isFlipped={isBack}
                                            size="small"
                                        />
                                    </Space>
                                ) : null
                            }
                        >
                            <video
                                ref={videoRef}
                                className={'DocumentScanner-video'}
                                id="myCameraVideoElement"
                                playsInline
                                style={{
                                    width: '100%',
                                    height: 'auto',
                                }}
                            ></video>
                            {loaded ? (
                                <div className="Reticle_container">
                                    <div className="Reticle is-default">
                                        {switchToBack ? (
                                            <CardFlip
                                                className={'Reticle_cursor'}
                                            />
                                        ) : scanFeedbackStatus ===
                                          'scanning' ? (
                                            <LoadingOutlined className="Reticle_cursor" />
                                        ) : scanFeedbackStatus === 'success' ? (
                                            <CheckOutlined className="Reticle_cursor" />
                                        ) : (
                                            <Icon
                                                className="Reticle_cursor"
                                                component={() => (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        aria-hidden="true"
                                                        role="img"
                                                        width="1em"
                                                        height="1em"
                                                        preserveAspectRatio="xMidYMid meet"
                                                        viewBox="0 0 32 32"
                                                    >
                                                        <path
                                                            d="M16 4C9.383 4 4 9.383 4 16s5.383 12 12 12s12-5.383 12-12S22.617 4 16 4zm0 2c5.535 0 10 4.465 10 10s-4.465 10-10 10S6 21.535 6 16S10.465 6 16 6zm0 7a3 3 0 1 0 .002 6.002A3 3 0 0 0 16 13z"
                                                            fill="currentColor"
                                                        />
                                                    </svg>
                                                )}
                                            />
                                        )}
                                    </div>
                                    {switchToBack ? (
                                        <div className="Reticle_message">
                                            {t('Voltear documento')}
                                        </div>
                                    ) : scanFeedbackMessage ? (
                                        <div className="Reticle_message">
                                            {scanFeedbackMessage}
                                        </div>
                                    ) : null}
                                </div>
                            ) : null}
                        </BackButtonWrapper>
                    </div>
                </div>
            }
        />
    );
}

export async function loadBlinkIdModule() {
    if (!BlinkIDSDK.isBrowserSupported()) {
        console.error('This browser is not supported by the SDK!');
    }
    const loadSettings = new BlinkIDSDK.WasmSDKLoadSettings(
        process.env.REACT_APP_SMARTFACE_BLINKID_LICENSE as string
    );
    // loadSettings.engineLocation = `${window.location.origin}/blinkIDResources`;
    loadSettings.engineLocation = `${window.location.origin}/resources`;
    loadSettings.blinkIdVariant = 'lightweight';
    loadSettings.wasmType = WasmType.Basic;

    wasmSDK = await BlinkIDSDK.loadWasmModule(loadSettings);
    blinkIdRecognizer =
        await BlinkIDSDK.createBlinkIdMultiSideRecognizer(wasmSDK);

    // Update recognizer settings before scan action
    // https://github.com/BlinkID/blinkid-in-browser/blob/9d07a2b38f2bee3ef5ab30414b1f26545ff4bdc6/src/Recognizers/BlinkID/Generic/BlinkIdRecognizer.ts#L91-L206

    const settings: RecognizerSettings =
        await blinkIdRecognizer.currentSettings();
    // @ts-ignore
    settings['returnFaceImage'] = true;
    // @ts-ignore
    settings['returnFullDocumentImage'] = true;
    //@ts-ignore
    await blinkIdRecognizer.updateSettings(settings);
}

async function loadOcrCaptureModule(callbacks: any, onlyFront?: boolean) {
    const loadSettings = new BlinkIDSDK.WasmSDKLoadSettings(
        process.env.REACT_APP_SMARTFACE_BLINKID_LICENSE as string
    );
    // loadSettings.engineLocation = `${window.location.origin}/blinkIDResources`;
    loadSettings.engineLocation = `${window.location.origin}/resources`;
    loadSettings.blinkIdVariant = 'lightweight';

    const wasmSDK = await BlinkIDSDK.loadWasmModule(loadSettings);
    let recognizer;
    if (onlyFront) {
        recognizer =
            await BlinkIDSDK.createBlinkIdSingleSideRecognizer(wasmSDK);
    } else {
        recognizer = await BlinkIDSDK.createBlinkIdMultiSideRecognizer(wasmSDK);
    }

    // Update recognizer settings before scan action
    // https://github.com/BlinkID/blinkid-in-browser/blob/9d07a2b38f2bee3ef5ab30414b1f26545ff4bdc6/src/Recognizers/BlinkID/Generic/BlinkIdRecognizer.ts#L91-L206

    const settings = await recognizer.currentSettings();
    settings['returnFaceImage'] = true;
    settings['returnFullDocumentImage'] = true;
    //@ts-ignore
    await recognizer.updateSettings(settings);

    const recognizerRunner = await BlinkIDSDK.createRecognizerRunner(
        wasmSDK,
        [recognizer],
        true,
        callbacks
    );
    return [recognizerRunner, recognizer];
}

export function normalizeUserInfo(raw: any, dateFormat: string) {
    const formattedDateOfBirth = raw.dateOfBirth
        ? `${raw.dateOfBirth.year}-${String(raw.dateOfBirth.month).padStart(
              2,
              '0'
          )}-${String(raw.dateOfBirth.day).padStart(2, '0')}`
        : '';
    return {
        firstName: raw.firstName?.latin.replace(/(\n)+/g, ' '),
        lastName: raw.lastName?.latin.replace(/(\n)+/g, ' '),
        dateOfBirth: dayjs(formattedDateOfBirth).format(dateFormat),
        nationality: raw.nationality?.latin,
        nationalityAlpha3: raw.mrz?.sanitizedNationality,
        documentNumber: raw.documentNumber?.latin,
    };
}
export function normalizeDocumentInfo(raw: any, dateFormat: string) {
    const formattedDateOfBirth = raw.dateOfBirth
        ? `${raw.dateOfBirth.year}-${String(raw.dateOfBirth.month).padStart(
              2,
              '0'
          )}-${String(raw.dateOfBirth.day).padStart(2, '0')}`
        : '';
    const formattedDateOfExpiry = raw.dateOfExpiry
        ? `${raw.dateOfExpiry.year}-${String(raw.dateOfExpiry.month).padStart(
              2,
              '0'
          )}-${String(raw.dateOfExpiry.day).padStart(2, '0')}`
        : '';
    const formattedDateOfIssue = raw.dateOfIssue
        ? `${raw.dateOfIssue.year}-${String(raw.dateOfIssue.month).padStart(
              2,
              '0'
          )}-${String(raw.dateOfIssue.day).padStart(2, '0')}`
        : '';

    let documentCode;
    //Mapping with MrtdDocumentType enum
    switch (raw.mrz?.documentType) {
        case 1:
            documentCode = DocumentCode.ID_CARD;
            break;
        case 2:
            documentCode = DocumentCode.PASSPORT;
            break;
        case 6:
            documentCode = DocumentCode.DRIVING_LICENSE;
            break;
    }
    return {
        firstName: raw.firstName?.latin.replace(/(\n)+/g, ' '),
        lastName: raw.lastName?.latin.replace(/(\n)+/g, ' '),
        dateOfBirth: dayjs(formattedDateOfBirth).format(dateFormat),
        placeOfBirth: raw.placeOfBirth?.latin,
        dateOfExpiry: dayjs(formattedDateOfExpiry).format(dateFormat),
        dateOfIssue: dayjs(formattedDateOfIssue).format(dateFormat),
        nationality: raw.nationality?.latin,
        nationalityAlpha3: raw.mrz?.sanitizedNationality,
        documentCode: documentCode,
        documentNumber: raw.documentNumber?.latin,
        documentIssuer: raw.classInfo?.countryName,
        documentIssuerAlpha3: raw.classInfo?.isoAlpha3CountryCode,
    };
}
