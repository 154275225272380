import React from 'react';
import { useTranslation } from 'react-i18next';
import { Space } from 'antd';
import Button from '../../common/Button';
import Icon from '@ant-design/icons';
import { imgResultErrorSvg, imgResultSuccessSvg } from '../../common/images';
import TitleText from '../../common/TitleText';

export default GwFlowValidateError;

export type GwFlowValidateErrorProps = {
    onFinishClick?: () => void;
    title?: string;
    subtitle?: string;
    buttonText?: string;
};

function GwFlowValidateError({
    onFinishClick,
    title,
    buttonText,
    subtitle,
}: GwFlowValidateErrorProps) {
    const { t } = useTranslation();

    return (
        <div className="GwFlowValidateError">
            <br />
            <br />
            <br />
            <br />
            <Icon component={imgResultErrorSvg} />
            <Space direction="vertical" size={20}>
                <TitleText size="large">
                    {t('¡No hemos podido validar tu identidad!')}
                </TitleText>
                <TitleText size="small">
                    {title ||
                        t('Ha ocurrido un error al validar tu identidad.')}
                </TitleText>
                <Button type="primary" size="large" onClick={onFinishClick}>
                    {buttonText || t('Finalizar')}
                </Button>
            </Space>
        </div>
    );
}
