import React, { Fragment } from 'react';
import { GwFlowGlobalResult } from '../GwFlow';
import { GwConfigStepType } from 'gw-api/types';
import Space from '../../common/Space';
import { useTranslation } from 'react-i18next';
import './Summary.css';
import { CheckCircleOutlined } from '@ant-design/icons';
import Image from '../../common/Image';
import {
    fieldTypes,
    getFieldsMap,
} from '../GwFlowPersonalInfo/GwFlowPersonalInfo';

export default Summary;

export type SummaryProps = {
    result: GwFlowGlobalResult;
};

function Summary({ result }: SummaryProps) {
    const { t } = useTranslation();

    const {
        [GwConfigStepType.DOCUMENT_CAPTURE]: documentData,
        [GwConfigStepType.SELFIE_CAPTURE]: selfieData,
        [GwConfigStepType.PERSONAL_INFO]: personalInfo,
        [GwConfigStepType.OTP_EMAIL]: otpEmail,
        [GwConfigStepType.OTP_SMS]: otpSms,
        [GwConfigStepType.WELCOME]: welcomeData,
    } = result;

    const fieldsMap = getFieldsMap(t);

    return (
        <div className="Summary">
            <Space wide={true} direction="vertical">
                {otpEmail ? (
                    <Space
                        className="Summary_item"
                        wide={true}
                        direction="horizontal"
                    >
                        <Space>
                            <span className="Summary_item-icon">
                                <CheckCircleOutlined />
                            </span>
                            <strong>Email</strong>
                        </Space>
                        <span>{otpEmail.email}</span>
                    </Space>
                ) : null}
                {otpSms ? (
                    <Space
                        className="Summary_item"
                        wide={true}
                        direction="horizontal"
                    >
                        <Space>
                            <span className="Summary_item-icon">
                                <CheckCircleOutlined />
                            </span>
                            <strong>{t('Numero de teléfono')}</strong>
                        </Space>
                        <span>{otpSms.phone}</span>
                    </Space>
                ) : null}
                {documentData && selfieData ? (
                    <Space
                        className="Summary_item"
                        wide={true}
                        direction="vertical"
                    >
                        <Space wide={true} justify="start">
                            <span className="Summary_item-icon">
                                <CheckCircleOutlined />
                            </span>
                            <strong>{t('Prueba de vida superada')}</strong>
                        </Space>
                        <Space wide={true} justify="end" align="end">
                            {documentData?.images?.face ? (
                                <Image
                                    src={`${documentData?.images?.face}`}
                                    style={{
                                        width: '100px',
                                        height: '100px',
                                        objectFit: 'cover',
                                    }}
                                    className={undefined}
                                    alt={undefined}
                                />
                            ) : documentData?.images?.front ? (
                                <Image
                                    src={`${documentData?.images?.front}`}
                                    style={{
                                        width: '100px',
                                        height: '100px',
                                        objectFit: 'cover',
                                    }}
                                    className={undefined}
                                    alt={undefined}
                                />
                            ) : (
                                <Image
                                    style={{
                                        width: '100px',
                                        height: '100px',
                                        objectFit: 'cover',
                                    }}
                                    className={undefined}
                                    src={undefined}
                                    alt={undefined}
                                />
                            )}
                            {selfieData?.bestImageCropped ? (
                                <Image
                                    src={`${selfieData?.bestImageCropped}`}
                                    style={{
                                        width: '100px',
                                        height: '100px',
                                        objectFit: 'cover',
                                    }}
                                    className={undefined}
                                    alt={undefined}
                                />
                            ) : (
                                <Image
                                    style={{
                                        width: '100px',
                                        height: '100px',
                                    }}
                                    className={undefined}
                                    src={undefined}
                                    alt={undefined}
                                />
                            )}
                        </Space>
                        {/*<span>{personalInfo}</span>*/}
                    </Space>
                ) : null}

                {personalInfo ? (
                    <Space
                        className="Summary_item"
                        wide={true}
                        direction="vertical"
                    >
                        <Space wide={true} justify="start">
                            <span className="Summary_item-icon">
                                <CheckCircleOutlined />
                            </span>
                            <strong>{t('Datos personales')}</strong>
                        </Space>
                        {fieldTypes.map((field) => {
                            if (personalInfo.personalInfo[field]) {
                                return (
                                    <Space wide={true} justify="space-between">
                                        <span>{fieldsMap[field].label}</span>
                                        <span>
                                            {personalInfo.personalInfo[field]}
                                        </span>
                                    </Space>
                                );
                            } else {
                                return null;
                            }
                        })}
                    </Space>
                ) : null}
            </Space>
        </div>
    );
}
